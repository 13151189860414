import React from "react";
import Contact from "./Contact";

export default class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-md navigation fixed-top sidebar-left navbar-sticky">
          <div className="container">
            {/* <button className="navbar-toggler" type="button">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button> */}
            <a href="/" className="navbar-brand">
              <img
                src="img/logos/bluebells.png"
                alt=""
                className="logo logo-sticky d-block d-md-none"
              />
              <img
                src="img/logos/bluebells.png"
                alt=""
                className="logo d-none d-md-block"
              />
            </a>
            <div className="collapse navbar-collapse ml-auto" id="main-navbar">
              <div className="sidebar-brand">
                <a href="/">
                  <img
                    src="img/llogo.png"
                    alt="Cronos Template"
                    className="logo"
                  />
                </a>
              </div>
              <ul className="nav navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#technologies">
                    Technologies
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#principles">
                    Principles
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#development">
                    Development
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="nav-overlay bg-5 alpha-8 gradient"></div>
        <main>
          <header
            className="section fullscreen header image-background cover d-flex align-items-end overflow-hidden parallax overlay overlay-3 alpha-8 b-b"
            style={{ backgroundImage: "url('img/bg/bubbles-dark.jpg')" }}
          >
            <div className="container text-center pb-0">
              <div className="row">
                <div className="col-12">
                  <h1 className="display-4 color-1 bold">
                    Realtime. Interactive. Customized.
                  </h1>
                  <p className="lead mb-5 color-1">
                    Let us nurture your business with our bluebells.{" "}
                  </p>
                </div>
                <div className="col-12">
                  <div className="mockup">
                    <img
                      className="img-responsive shadow mt-5 mx-auto"
                      src="img/screens/responsive-prime.png"
                      alt="..."
                      data-aos="fade-up"
                      data-aos-offset="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <span id="technologies" />
          <section className="section features reasons bg-6-gradient b b-b">
            <div className="container">
              <p className="light text-center text-uppercase color-2 alpha-6 mb-5 mt-0">
                Our Technologies
              </p>
              <div className="row gap-y">
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/react.png"
                          alt=""
                          className="img-responsive"
                          title="We live and breathe React. It's the driving force to experience the bluebells realtime approach."
                        />
                      </i>
                      <br />
                      <p>
                        We live and breathe React. It's the driving force to
                        experience the bluebells realtime approach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/react_native.png"
                          alt=""
                          className="img-responsive"
                          title="Reactive bluebells in your palm."
                        />
                      </i>
                      <br />
                      <p>
                        Native and reactive bluebells at the palm of your hands.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/nodejs.png"
                          alt=""
                          className="img-responsive"
                          title="Our primary stack is NodeJS. But we love Python, Go, Java and C# too."
                        />
                      </i>
                      <br />
                      <p>
                        Our primary stack is NodeJS. But we love Python, Go,
                        Java and C# too.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/nginx.png"
                          alt=""
                          className="img-responsive"
                          title="When we build services we think in Nginx. We keep finding new ways to use it on daily basis."
                        />
                      </i>
                      <br />
                      <p>
                        When we build services we think in Nginx. We keep
                        finding new ways to use it on daily basis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/aws.png"
                          alt=""
                          className="img-responsive"
                          title="When heavy lifting is required we stand on the shoulders of giants. AWS is our preferred giant and we speak its language (CloudFormation + Elasticity)."
                        />
                      </i>{" "}
                      <br />
                      <p>
                        When heavy lifting is required we stand on the shoulders
                        of giants. AWS is our preferred giant and we speak its
                        language.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/docker.png"
                          alt=""
                          className="img-responsive"
                          title="Bluebells love Dockers. When the cloud is too much and Cloud Native is just enough we let Kubernetes handle the play."
                        />
                      </i>
                      <br />
                      <p>
                        Bluebells love Dockers. When the cloud is too much and
                        Cloud Native is just enough we let Kubernetes handle the
                        play.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/postgres.png"
                          alt=""
                          className="img-responsive"
                          title="PostgreSQL and the GIS, Pipeline, Multicorn plugins are our engine."
                        />
                      </i>
                      <br />
                      <p>
                        PostgreSQL and the PostGIS, PipelineDB and Multicorn
                        plugins are our engine.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/logos/kafka.png"
                          alt=""
                          className="img-responsive"
                          title="We tame the torrents of unstopable data with Kafka. We have high hopes in Kafka Streams and KSQL too."
                        />
                      </i>
                      <p>
                        We tame the torrents of unstopable data with Kafka. We
                        have high hopes in Kafka Streams and KSQL too.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <span id="principles" />
          <section className="section features reasons bg-6 bg-6-gradient-start">
            <div className="container">
              <div className="section-heading text-center">
                <h2 className="bold">Our Driving Principles</h2>
              </div>
              <div className="row text-center">
                <div className="col-md-4 py-4">
                  <img
                    src="img/svg/checklist.svg"
                    className="icon m-0"
                    alt=""
                  />
                  <h5 className="regular accent my-3">100% Realtime</h5>
                  <p className="my-0">
                    Time is precious, we solve the problems realtime first. It's
                    the most natual way.
                  </p>
                </div>
                <div className="col-md-4 py-4">
                  <img
                    src="img/svg/graphic-design.svg"
                    className="icon m-0"
                    alt=""
                  />
                  <h5 className="regular accent my-3">100% Testing</h5>
                  <p className="my-0">
                    Failure and errors are natural, so is fully incorporated
                    automated and repeatable testing.
                  </p>
                </div>
                <div className="col-md-4 py-4">
                  <img src="img/svg/startup.svg" className="icon m-0" alt="" />
                  <h5 className="regular accent my-3">100% DevOps</h5>
                  <p className="my-0">
                    Every piece of code we produce is packaged and delivered
                    continuously or on demand.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <span id="development" />
          <section className="section features reasons bg-6-gradient b b-b">
            <div className="container">
              <div className="section-heading text-center">
                <h2 className="bold">
                  A complete realtime solution for all your business needs.
                </h2>
                <p className="lead mt-0">
                  Take control of your data and actions with our realtime
                  Bluebells.{" "}
                </p>
              </div>
              <div className="row gap-y">
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/responsive.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Responsiveness</p>
                      <p>
                        Our bluebells will give you seamless experince accross
                        different screens.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/customization.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Microservices</p>
                      <p>
                        All our bluebells are microservices or components. You
                        can plug them in and out.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/browser.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Connected</p>
                      <p>
                        Our bluebells can be securely used and reused accross
                        different contexts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/code.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Clean Code</p>
                      <p>
                        Every part of our blubells is self contained inside
                        their own code repositories.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/headset.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Supported</p>
                      <p>
                        If our bluebells don't work properly we'll kick in and
                        listen out first hand.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/document.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Documented</p>
                      <p>
                        Each bluebell has associated documentation with it.{" "}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/idea.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Innovation</p>
                      <p>
                        We invest a lot ot make our bluebells work for you the
                        way you need them to.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadow-box border-0 card">
                    <div className="card-body d-flex flex-column align-items-center">
                      <i className="w-50">
                        <img
                          src="img/svg/ootb.svg"
                          className="img-responsive"
                          alt=""
                        />
                      </i>
                      <br />
                      <p>Shippable</p>
                      <p>
                        You can take each of our bluebells and ship them right
                        away from their repositories.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <span id="team" />
        {/* <section className="section features reasons bg-6-gradient b b-b">
          <div className="container">
            <div className="section-heading text-center">
              <h2 className="bold">The people behind Bluebells</h2>
              <p className="lead mt-0">Commitment, mutual trust and respect and love of technology is what keeps us together.  </p>
            </div>
            <div className="row gap-y">
              <div className="col-md-3">
                <div className="shadow-box border-0 card">
                  <div className="card-body d-flex flex-column align-items-center">
                    <i className="w-50">
                      <img src="img/avatars/jeton.svg"
                        className="img-responsive" alt="" />
                    </i>
                    <br />
                    <p>Jeton K. - React and React Native</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shadow-box border-0 card">
                  <div className="card-body d-flex flex-column align-items-center">
                    <i className="w-50">
                      <img src="img/avatars/visar.svg"
                        className="img-responsive" alt="" />
                    </i>
                    <br />
                    <p>Visar E. - AWS, DevOps and Microservices</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shadow-box border-0 card">
                  <div className="card-body d-flex flex-column align-items-center">
                    <i className="w-50">
                      <img src="img/avatars/sadat.svg"
                        className="img-responsive" alt="" />
                    </i>
                    <br />
                    <p>Anonymous - PostgreSQL and Kafka</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shadow-box border-0 card">
                  <div className="card-body d-flex flex-column align-items-center">
                    <i className="w-50">
                      <img src="img/avatars/alma.svg"
                        className="img-responsive" alt="" />
                    </i>
                    <br />
                    <p>Anonymous - User Experience and CSS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <span id="contact" />
        <Contact />
        <footer className="site-footer section b-t">
          <div className="container pb-3">
            <div className="row gap-y text-center text-md-left">
              <div className="col-md-5 mr-auto">
                <img src="img/logos/bluebells.png" alt="" className="logo" />
                <p className="color-2 alpha-5">
                  Let us nurture your business with our bluebells.
                </p>
                <nav className="nav mt-3 color-3 justify-content-center justify-content-md-start">
                  <a className="nav-link pl-0" href="variation-2.html#">
                    <i className="fa-facebook"></i>
                  </a>
                  <a className="nav-link" href="variation-2.html#">
                    <i className="fa-twitter"></i>
                  </a>
                  <a className="nav-link" href="variation-2.html#">
                    <i className="fa-behance"></i>
                  </a>
                  <a className="nav-link" href="variation-2.html#">
                    <i className="fa-instagram"></i>
                  </a>
                </nav>
              </div>
              <div className="col-md-2">
                <h6 className="bold text-uppercase">Company</h6>
                <nav className="nav flex-column mt-3">
                  <a href="#technologies" className="my-2">
                    Technologies
                  </a>
                  <a href="#principles" className="my-2">
                    Principles
                  </a>
                  <a href="#development" className="my-2">
                    Development
                  </a>
            
                  <a href="#contact" className="my-2">
                    Contact
                  </a>
                </nav>
              </div>
            </div>
            <hr className="mt-5 bg-6" />
            <div className="row small align-items-center text-center text-md-left">
              <div className="col-md-4">
                <p className="mt-2 mb-0">
                  © 2018 BlueBells. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
