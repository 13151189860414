import React from 'react';

export default class Contact extends React.Component {

    render() {
        return (
            <main>
                <section className="section bg-6-gradient b-b">
                    <div className="container">
                        <div className="section-heading">
                            <h2>Get in touch</h2>
                            <p className="lead">We are here to help, drop us a line if you have any question</p>
                        </div>
                        <div className="row gap-y align-content-stretch">
                            <div className="col-lg-6">
                                <div className="card border-0 shadow-box">
                                    <div className="card-body">
                                        <form action="https://formspree.io/info@bluebells.io" method="POST" className="form form-contact"
                                            name="form-contact" data-response-message-animation="slide-in-up">
                                            <div className="form-group"><label htmlFor="contact_fullname">Full name</label><input type="text"
                                                name="fullName" id="contact_fullname" className="form-control bg-1"
                                                placeholder="Enter your full Name" required /></div>
                                            <div className="form-group"><label htmlFor="contact_email">Email address</label><input type="email"
                                                name="email" id="contact_email" className="form-control bg-1"
                                                placeholder="Valid Email" required /> <small id="emailHelp" className="form-text text-muted">We'll
                                            never share your email with anyone else.</small></div>
                                            <div className="form-group"><label htmlFor="contact_email">Subject</label><input type="text"
                                                name="subject" id="contact_subject" className="form-control bg-1"
                                                placeholder="Subject" required /></div>
                                              <div className="form-group"><label htmlFor="contact_email">Message</label><textarea name="message"
                                                id="contact_message" className="form-control bg-1" placeholder="What do you want to let us know?"
                                                rows="8" required></textarea></div>
                                            <div className="form-group"><button id="contact-submit" data-loading-text="Sending..."
                                                type="submit" className="btn btn-primary px-5 py-3">Send Message</button></div>
                                        </form>
                                        <div className="response-message">
                                            <div className="section-heading"><i className="fa-thumbs-o-up font-lg mb-2"></i>
                                                <h2 className="bold">Thank you!!!</h2>
                                                <p className="response">Your message has been send, we will contact you as soon as
                                            possible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card border-0 shadow-box h-100">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}
